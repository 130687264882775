import Repository from './Repository';

export default {
  log(payload: {
    action: string,
    params?: Record<string, any>,
  }): Promise<any> {
    return Repository.post('api/log', payload, {
      withCredentials: true,
    });
  },
};
