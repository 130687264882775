
import logRepository from '@/api/logRepository';
import RepositoryFactory from '@/api/RepositoryFactory';
import { LogAction } from '@/types/logAction';
import {
  Component, Vue, Watch,
} from 'vue-property-decorator';

const authRepository = RepositoryFactory.auth;

@Component
export default class Header extends Vue {
  marginTop = '0px';

  get loading() {
    return this['$store'].getters.loading;
  }

  get isMobile() {
    return window.innerWidth <= 1200;
  }

  get header() {
    return this['$store'].getters.header;
  }

  get headerForegroundColor() {
    return this['$store'].getters.header?.foregroundColor ?? '#fff';
  }

  get headerBackgroundColor() {
    return this['$store'].getters.header?.backgroundColor ?? 'primary';
  }

  get externalLinks() {
    return this['$store'].getters.header?.externalLinks ?? [];
  }

  get showTitle() {
    return this.externalLinks.length <= 0 || !this.isMobile;
  }

  get showDrawer() {
    window.scrollTo(0, 0);
    return this['$store'].getters.showDrawer;
  }

  set showDrawer(value) {
    this['$store'].commit('setShowDrawer', value);
  }

  get appTitle() {
    return this.header?.name ?? '';
  }

  // eslint-disable-next-line class-methods-use-this
  private clickDropDownItem(link) {
    window.open(link);
  }

  async logout() {
    await logRepository.log({
      action: LogAction.Logout,
    });
    await authRepository.logout();
    this['$router'].push('/login');
  }

  @Watch('loading')
  async setMarginTop() {
    setTimeout(() => {
      const element = document.getElementById('notice');
      if (element !== null) {
        const height = element.clientHeight;
        this.marginTop = `${height}px`;
      }
    }, 300);
  }
}
