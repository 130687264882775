
import { Component, Vue, Watch } from 'vue-property-decorator';
import { deleteAllCookie } from '@/functions';
import RepositoryFactory from '@/api/RepositoryFactory';
import { LogAction } from '@/types/logAction';
import Spinner from 'vue-spinner/src/ScaleLoader.vue';
import Header from '../components/organisms/Header.vue';
import Notice from '../components/organisms/Notice.vue';
import Tabs from '../components/molecules/Tabs.vue';
import DamPrediction from '../components/organisms/tabs/DamPrediction.vue';

const authRepository = RepositoryFactory.auth;
const logRepository = RepositoryFactory.log;

@Component({
  components: {
    Header,
    Notice,
    Spinner,
    Tabs,
    DamPrediction,
  },
})
export default class Login extends Vue {
  private showPassword = false;

  private userId = '';

  private password = '';

  private error = false;

  get loading() {
    return this['$store'].getters.loading;
  }

  get header() {
    return this['$store'].getters.header;
  }

  private async login() {
    this.error = false;
    try {
      deleteAllCookie();
      const { status } = await authRepository.login({
        username: this.userId,
        password: this.password,
      });

      if (status === 200) {
        await logRepository.log({
          action: LogAction.Login,
        });
        this['$router'].push('/');
      }
    } catch (error) {
      if ([401, 403].includes(error.response.status)) {
        this.error = true;
      }
    }
  }

  @Watch('header')
  changeTitle() {
    document.title = this.header.name;
  }
}
